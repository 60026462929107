exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polityka-js": () => import("./../../../src/pages/polityka.js" /* webpackChunkName: "component---src-pages-polityka-js" */),
  "component---src-pages-porady-js": () => import("./../../../src/pages/porady.js" /* webpackChunkName: "component---src-pages-porady-js" */),
  "component---src-pages-porady-kierowca-uciekl-z-miejsca-wypadku-js": () => import("./../../../src/pages/porady/kierowca-uciekl-z-miejsca-wypadku.js" /* webpackChunkName: "component---src-pages-porady-kierowca-uciekl-z-miejsca-wypadku-js" */),
  "component---src-pages-porady-samochod-z-oc-sprawcy-js": () => import("./../../../src/pages/porady/samochod-z-oc-sprawcy.js" /* webpackChunkName: "component---src-pages-porady-samochod-z-oc-sprawcy-js" */),
  "component---src-pages-porady-wypadek-drogowy-js": () => import("./../../../src/pages/porady/wypadek-drogowy.js" /* webpackChunkName: "component---src-pages-porady-wypadek-drogowy-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */)
}

